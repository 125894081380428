import $ from 'jquery';
import 'bootstrap';
import Filterizr from 'filterizr'
import Swiper, {SwiperOptions, Autoplay} from "swiper"
Swiper.use([Autoplay]);

const general = {
    onScrollHeader: function() {
        $(window).scroll(function() {
            const scroll = window.pageYOffset;
            if (scroll >= 100) {
                $("header .navbar").addClass("-scrolled");
            } else {
                $("header .navbar").removeClass("-scrolled");
            }
        });
    },
    initSwiper: function() {
        var swiper = new Swiper('.swiper-container', {
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        });
    },
    initFilterizr: function() {
        if ($('.filter-container').length) {
            const options = {
                animationDuration: 0.5, // in seconds
                callbacks: {
                    onFilteringStart: function() { },
                    onFilteringEnd: function() { },
                    onShufflingStart: function() { },
                    onShufflingEnd: function() { },
                    onSortingStart: function() { },
                    onSortingEnd: function() { }
                },
                controlsSelector: '', // Selector for custom controls
                delay: 25, // Transition delay in ms
                delayMode: 'progressive', // 'progressive' or 'alternate'
                easing: 'ease-out',
                filter: 'all', // Initial filter
                gutterPixels: 0, // Items spacing in pixels
                // gridItemsSelector: '.filtr-container',
                layout: 'sameSize', // See layouts
                multifilterLogicalOperator: 'or',
                setupControls: true, // Should be false if controlsSelector is set
                spinner: { // Configuration for built-in spinner
                    enabled: false,
                    fillColor: '#2184D0',
                    styles: {
                        height: '75px',
                        margin: '0 auto',
                        width: '75px',
                        'z-index': 2,
                    },
                },
            };
            const filterizr = new Filterizr('.filter-container', options)
        }
    },
    treeHandler: function() {
        $('.js-handle-popover').on('click', function() {
            let $this = $(this);
            $('.tree-side__popover-wrapper').removeClass('-modal-visible');
            $('.tree-side').addClass('-modal-open');
            $this.parent().addClass('-modal-visible');
        });

        $('.tree-side__popover-close').on('click', function() {
            $('.tree-side').removeClass('-modal-open');
            $('.tree-side__popover-wrapper').removeClass('-modal-visible');
        })
    },
    menuHandler: function() {
        $('#navbarSupportedContent')
            .on('hide.bs.collapse', function () {
                $('header .navbar-collapser').removeClass('-extended');
            })
            .on('show.bs.collapse', function () {
                $('header .navbar-collapser').addClass('-extended');
            })
    },
    marketplaceHandler: function() {

        // Reset all values to no have incoherence
        $('#js-marketplace-filters').find('input[type="checkbox"]').prop('checked', false);

        // Filter handlers
        $('.js-marketplace-filter').on('click', function() {

            if ($(this).data('filter') === 'all') {

                // Resettings others buttons and checkbox
                $('.js-marketplace-filter').removeClass('-active');
                $('.js-marketplace-checkbox').find('input[type="checkbox"]').prop('checked', false);
                $(this).addClass('-active');

            } else {

                if ($(this).hasClass('-active')) {
                    $(this).removeClass('-active');
                } else {
                    $(this).addClass('-active');
                    $('.js-marketplace-filter[data-filter="all"]').removeClass('-active');
                }
            }
        });


        // if a checkbox is active remove -active class on all button
        $('.js-marketplace-checkbox input[type="checkbox"]').on('change', function(k, v) {
                // if ($(v).prop('checked') === true) {
                    $('.js-marketplace-filter[data-filter="all"]').removeClass('-active');
                // }
        });

        // Articles handlers
        $('.js-marketplace-article').each(function(k, v) {
            let item = $(v);

            if (item.find('input[type="checkbox"]').prop('checked') === true) {
                item.addClass('-selected');
            }
        });

        // Article modifier to get background white or not if selected or not
        $('.js-marketplace-article').on('click', function(e) {
            if (!$(e.target).hasClass('js-marketplace-popover')) {
                if ($(this).hasClass('-selected')) {
                    $(this).removeClass('-selected');
                } else {
                    $(this).addClass('-selected');
                }

                let chb = $(this).find('input[type="checkbox"]');
                chb.prop('checked', !chb.prop('checked'));


                // Form submit handler
                if ($('.js-marketplace-form').find('article input[type="checkbox"]:checked').length === 0){
                    $('.js-marketplace-form [type="submit"]').prop('disabled', 'disabled');
                } else {
                    console.log('in');
                    $('.js-marketplace-form [type="submit"]').prop('disabled', false);
                }
            }
        })

        // Check at initialization if there is an input checked or not to disable submit
        if ($('.js-marketplace-form input[type="checkbox"]:checked').length === 0) {
            $('.js-marketplace-form [type="submit"]').prop('disabled', 'disabled');
        }
    }
};
export default general;

(function($){
    $(document).ready(function () {
        general.onScrollHeader();
        general.initSwiper();
        general.treeHandler();
        general.menuHandler();
        general.initFilterizr();
        general.marketplaceHandler();

        $(function () {
            $('[data-toggle="popover"]').popover()
        })
    });
})($);
